<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·已入客户库联系人/未入客户库联系人的页面
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
	<div>
		<div class="crm-content link-content">
			<!-- 顶部导航栏位置 -->
			<div class="crm-content-title">
				<i class="icon iconfont icon-zhuye index-icon" @click="returnCrm"></i>
				<span class="crm-content-site" v-for="title in myTitle">您的位置：{{title.name}}</span>
			</div>
			<!-- 表格 -->
			<div class="crm-table ">
				<div class="w100">
					<table width="100%" cellpadding="0" cellspacing="0">
						<thead>
						<tr>
							<th height="30" class="th" style="width: 20px; padding: 10px 0px;"></th>
							<th height="30" class="th" width="20"></th>
							<th height="30" class="th">
								<span>联系人信息</span>
							</th>
							<th height="30" class="th" width="150">
								<span>创建时间</span>
							</th>
							<th height="30" class="th" width="150">
								<span>最后联系时间</span>
							</th>
							<th height="30" class="th" width="150">
								<span>发信量</span>
							</th>
							<th height="30" class="th" width="150">
								<span>收信量</span>
							</th>
							<th height="30" class="th" width="150">
								<span>操作</span>
							</th>
							 <th width="18" class="th" v-show='linkmanList.length>9' style="padding: 8px 0;"></th>
						</tr>
					</thead>
					</table>
				</div>
				<div class="w100 newTableStyle" style="top: 85px;">
					<table width="100%" cellpadding="0" cellspacing="0" v-loading="autographLoading" element-loading-text="拼命加载中">
					<tbody>
						<!-- 总计 -->
						<tr v-for="one in firstLists" v-if="linkmanList.length > 0">
							<td class="td" height="50" style="width: 20px; padding: 10px 0px;"></td>
							<td class="td" height="50" width="20"></td>
							<td class="td" height="50" style="color: red;">{{one.total_name}}</td>
							<td class="td" height="50" width="150" style="color: red;">--</td>
							<td class="td" height="50" width="150" style="color: red;">--</td>
							<td class="td" height="50" width="150" style="color: red;">{{one.sNumber_total}}</td>
							<td class="td" height="50" width="150" style="color: red;">{{one.rNumber_total}}</td>
							<td v-if="hasInWarehouse" class="td" height="50" width="150" style="color: red;">--</td>
							<td v-if="!hasInWarehouse" class="td" height="50" width="150" style="color: red;">--</td>
						</tr>
						<tr v-for="(td,index) in linkmanList">
							<td class="td" height="50" style="width: 20px; padding: 10px 0px;"></td>
							<td class="td" height="50" width="20">
								<span v-if="td.is_main==1" class="spot" title="主联系人"></span>
							</td>
							<td class="td" height="50">
								<div>
									<img src="../../../../../static/img/common/linkMan.png" alt="" class="cus_bg" />
									<span class="cus_info">
					                    <p class="cus_name" @click="lookLinkman(td.linkman_id)" :title="td.name">{{td.name}}</p>
					                    <p class="cus_email" @click="clickMessage(td.email,td.linkman_id,td.customer_id)" title="点击快捷写信">{{td.email}}</p>
					                </span>
								</div>
							</td>
							<td class="td" height="50" width="150">
								<div :title="td.createTime">
									<span>{{td.createDate2}}</span>
								</div>
							</td>
							<td class="td" height="50" width="150">
								<div v-if="td.lastDate ==''">暂未联系</div>
								<div v-if="td.lastDate !=''" :title="td.lastTime">{{td.lastDate}}</div>
							</td>
							<td class="td" height="50" width="150">
								<div v-if="td.sNumber == -1"><i class="el-icon-loading"></i></div>
								<div v-if="td.sNumber != -1"> <span>{{td.sNumber}}</span></div>
							</td>
							<td class="td" height="50" width="150">
								<div v-if="td.rNumber == -1"><i class="el-icon-loading"></i></div>
								<div v-if="td.rNumber != -1"><span>{{td.rNumber}}</span></div>
							</td>
							<td v-if="hasInWarehouse" class="td" height="50" width="150">
								<span title="删除" class="alibabaicon">
              		<i v-if="td.is_main==0" class="icon iconfont icon-lajitong1" @click="deleteLinkman(td.linkman_id)"></i>
              	</span> &nbsp;
								<span title="定位到客户" class="alibabaicon">
                	<i class="icon iconfont icon-location" @click="localtionCustomer(td.customer_id)" title="定位到客户"></i>
              	</span>
							</td>
							<td v-if="!hasInWarehouse" class="td" height="50" width="150">
								<span title="编辑" class="alibabaicon">
              		<i class="icon iconfont icon-bianji1" @click="editLinkman(td.linkman_id)"></i>
              	</span> &nbsp;
								<span title="删除" class="alibabaicon">
                	<i class="icon iconfont icon-lajitong1" @click="deleteLinkman(td.linkman_id)"></i>
                </span> &nbsp;
								<span title="添加客户" class="alibabaicon">
                	<i class="icon iconfont icon-add" @click="toCustomer(td.linkman_id)"></i>
                </span>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- 暂无数据 -->
				<div class="myNoData" v-show="linkmanList.length == 0">
					<p><i class="icon iconfont icon-zanwushuju"></i></p>
					<span>暂无数据</span>
				</div>	
				</div>
			</div>
			<!-- 分页 -->
			<div class="newPagingStyle" v-if="linkmanList.length>0">
				<!--分页-->
				<el-pagination class="crmCCenter" :current-page.sync="currpage" :page-sizes="[10,20,30,40,50]" :page-size="pagesize" layout="total,sizes, prev, pager, next, jumper" :page-count='tolpage' :total="tolRecord" @size-change='sizeChange' @current-change='getLinkmanList()'></el-pagination>
			</div>
		</div>
		<!--查看联系人-->
		<small-layer v-if="showC" @cntClose="showC = false" :connect-tab="connectTab" :client-list="false" cnt-title="联系人详情" :has-button="false"></small-layer>
		<!--编辑联系人-->
		<newLinkman :linkman_id="linkman_id" :classify_id="0" :customer_id="0" class="module-linkman" v-if="showL" @cntClose="showL = false" @linkmanReturn="linkmanReturn" cnt-title="编辑联系人" :has-button="true" :isNewEdite="true"></newLinkman>
		<!--快捷写信-->
		<writeMessage @closeMessage="closeMessage" @writeMessage="writeMessages" v-if="showMessage" :my-email="myEmail" class="writeMessage" :cusorlink="customer_id>0?1:0" :id="linkman_id" :cusId="customer_id"></writeMessage>
		<!--添加为客户或转为客户联系人-->
		<create-client v-if="crC" :linkId="linkman_id" @successReturn="successReturn" @close="crC=false"></create-client>
	</div>
</template>
<script>
	import smallLayer from '../../CommonItem/smallLayer.vue'
	import newLinkman from "../../CommonItem/layer_newLinkman.vue"
	import createClient from './common_createLink.vue'
	import writeMessage from '../../CommonItem/writeMessage.vue'
	import { Message } from 'element-ui';
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
	export default({
		data() {
			return {
				currpage: 1,
				tolpage: 1,
				pagesize: 10,
				tolRecord: 1, //总条数
				showMessage: false,//快捷写信弹窗
				autographLoading: false, //加载框
				linkmanList: [], //联系人列表
				connectTab: [], //联系人信息实体
				showC: false,
				showL: false,
				crC: false,
				myEmail: '',
				linkman_id: 0,//联系人id
				firstLists: [], //总计
				customer_id:0,//客户id
			}
		},
		components: {
			writeMessage,
			smallLayer,
			newLinkman,
			createClient,
		},
		props: [
			'myTitle',
			'hasInWarehouse', //true已入库联系人,false未入库联系人
		],
		computed: {
			...mapState({
				userinfo: state => state.userinfo,
				customerclassifyall: state => state.customerclassifyall,
				customerfromall: state => state.customerfromall,
				customerrelationall: state => state.customerrelationall,
				customerstatusall: state => state.customerstatusall,
				industryType: state => state.hangyeArr,
				majorproductall: state => state.majorproductall,
				necessaryitemall: state => state.necessaryitemall,
				countryArr: state => state.countryArr,
				userparamlist: state => state.userparamlist,
				companyparamlist: state => state.companyparamlist,
				userlistall: state => state.userlistall,
				daySurplusCusCounts: state => state.daySurplusCusCounts,
				saleprocessall: state => state.saleprocessall,
				loadCusNumTag: state => state.loadCusNumTag,
				skipCusId: state => state.skipCusId,
			}),
		},
		methods: {
			//定位到客户
			localtionCustomer(cus_id) {
				this.$store.state.skipCusId = cus_id;
				$('.crm-tab-list-li').removeClass('selected');
				this.$router.push({ path: '/crm/allClient' });
			},
			//快捷写信
			clickMessage(email, linkid,cusId) {
				if(this.userparamlist.param39 == undefined || this.userparamlist.param39 == 0) {
					this.$message({
						showClose: true,
						message: '您还未开通邮件模块！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				this.myEmail = email;
				this.linkman_id = linkid;
				this.customer_id = cusId;
				this.showMessage = true;
			},
			//跳转到完整写信
			writeMessages(emails) {
				this.$router.push({
					path: '/mail'
				});
				this.$store.state.mail.wlPagetype = "";
				this.$store.state.mail.wlMailid = 0;
				this.writeLetter({
					name: '写信',
					addressee: emails
				});
				this.showMessage = false; //关闭快捷写信的弹窗
			},
			//查看联系人
			lookLinkman(linkid) {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "link_findByLinkid.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						linkmanid: linkid
					},
					success: function(msg) {
						lef.connectTab = [];
						lef.connectTab = [{
								"title": "姓名",
								"info": msg.full_name
							},
							{
								"title": "职位",
								"info": msg.post
							},
							{
								"title": "邮箱",
								"info": msg.email
							},
							{
								"title": "手机",
								"info": msg.mobile
							},
							{
								"title": "性别",
								"info": msg.sex
							},
							{
								"title": "地址",
								"info": msg.address
							},
							{
								"title": "网址",
								"info": msg.website
							}
						];
					}
				});
				this.showC = true;
			},
			//删除联系人
			deleteLinkman(linkid) {
				this.$confirm('确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
					cancelButtonClass: 'MBcancelBtn'
				}).then(() => {
					var action = "link_delLinkMan.action"; //已入库
					var lef = this;
					if(!lef.hasInWarehouse) {
						action = "link_deletenocuslinkman.action"; //未入库
					}
					$.ajax({
						type: "POST",
						url: backUrl + action,
						async: false,
						cache: false,
						dataType: "json",
						data: {
							token: token,
							linkmanid: linkid
						},
						success: function(msg) {
							if(msg == 1) {
								lef.$message({
									showClose: true,
									message: '删除成功！',
									type: 'success',
									duration: 2000
								});
								lef.getLinkmanList();
								if(lef.hasInWarehouse) {
									//重新加载已入库联系人数量
									lef.$store.state.loadCusNumTag = 8;
								} else {
									//重新加载未入库联系人数量
									lef.$store.state.loadCusNumTag = 9;
								}
							} else {
								lef.$message({
									showClose: true,
									message: '删除失败！',
									type: 'warning',
									duration: 2000
								});
							}
						}
					});
				}).catch(() => {});
			},
			//编辑联系人
			editLinkman(linkid) {
				this.linkman_id = linkid;
				this.showL = true;
			},
			//编辑联系人成功
			linkmanReturn(isMain) {
				this.getLinkmanList();
				this.showL = false;
			},
			//页数改变执行
			sizeChange(size) {
				this.pagesize = size;
				this.getLinkmanList();
			},
			//加载联系人列表
			getLinkmanList() {
				var lef = this;
				var value = 1; //已入库
				if(!lef.hasInWarehouse) {
					value = 2; //未入库
				}
				lef.linkmanList = [];
				lef.firstLists = [];
				$.ajax({
					type: "POST",
					url: backUrl + "link_findAllLinkByUserid.action",
					dataType: "json",
					cache: false,
					async: true,
					beforeSend: function() {
						lef.autographLoading = true;
					},
					complete: function() {
						lef.autographLoading = false;
					},
					data: {
						token: token,
						pagesize: lef.pagesize,
						currpage: lef.currpage,
						iscustomerlink: value
					},
					success: function(msg) {
						lef.tolpage = msg.pageBean.totalPage;
						lef.tolRecord = msg.pageBean.totalRecord;
						$.each(msg.crmlinkmanlist, function(index, row) {
							var createDate1 = "未知";
							var createDate2 = "暂无联系";
							var createTime = "暂无联系";
							if(row.enrol_time != null && row.enrol_time != "") {
								createDate1 = row.enrol_time.substring(0,row.enrol_time.length-2);
								createDate2 = createDate1.split(" ")[0];
								createTime = createDate1.split(" ")[1];
							}
							var lastDate = "";
							var sNumber = -1;
							var rNumber = -1;
							lef.linkmanList.push({
								"linkman_id": row.linkman_id,
								"customer_id": row.customer_id,
								"is_main": row.is_main_linkman,
								"name": row.full_name,
								"email": row.email,
								"createDate2":createDate2,
								"createTime":createTime,
								"lastDate": "",
								"lastTime": "",
								"sNumber": sNumber,
								"rNumber": rNumber,
							});
						});
						lef.get_emailInfoAsync();
					}
				});
			},
			//获取最后联系时间、发信量、收信量
			get_emailInfoAsync() {
				var lef = this;
				var sNumber_total = 0;
				var rNumber_total = 0;
				var arr_json = [];
				$.each(lef.linkmanList,function(n,m){
					$.ajax({
						type: "POST",
						url: backUrl + "linkcount_getLinkmanMailCount.action",
						data: {
							token: token,
							linkmanid:m.linkman_id
						},
						dataType: "json",
						cache: false,
						async: true,
						success: function(msg) {
							var lastDate = "暂无联系";
							var lastTime = "暂无联系";
							if(msg != null && msg != "" && msg != undefined){
									if(msg.last_contact_time != null && msg.last_contact_time != "") {
										lastDate = msg.last_contact_time.replace('T', ' ').split(" ")[0];
										lastTime = msg.last_contact_time.replace('T', ' ').split(" ")[1];
									}
									lef.linkmanList[n].lastDate = lastDate;
									lef.linkmanList[n].lastTime = lastTime;
									lef.linkmanList[n].sNumber = msg.send_mail;
									lef.linkmanList[n].rNumber = msg.recieve_mail;
									sNumber_total += msg.send_mail;
									rNumber_total += msg.recieve_mail;
									arr_json.push({
										total_name:'总计',
										sNumber_total:sNumber_total,
										rNumber_total:rNumber_total
									})
									lef.firstLists = arr_json.slice(-1)
							}else{
								lef.linkmanList[n].lastDate = lastDate;
								lef.linkmanList[n].sNumber = 0;
								lef.linkmanList[n].rNumber = 0;
								sNumber_total += 0;
								rNumber_total += 0;
								arr_json.push({
									total_name:'总计',
									sNumber_total:sNumber_total,
									rNumber_total:rNumber_total
								})
								lef.firstLists = arr_json.slice(-1)
							}
						},
						error: function(e) {
							console.log("加载失败!")
						}
					});
				})
			},
			
			//添加客户或转为客户联系人
			toCustomer(linkid) {
				this.linkman_id = linkid;
				this.crC = true;
			},
			//添加客户或转为客户联系人返回
			successReturn(type) {
				this.crC = false;
				if(type) { //转为客户
					this.$store.state.loadCusNumTag = 6;
				} else { //转为客户联系人
					//重新加载未入库联系人数量&&已入库联系人数量
					this.$store.state.loadCusNumTag = 10;
				}
			},
			returnCrm() {
				this.$emit('returnCrm')
			},
			...mapActions([
				'writeLetter'
			]),
			//关闭写信弹窗
			closeMessage() {
				this.showMessage = false;
			}
		},
		mounted() {
			this.getLinkmanList();//加载页面数据
		}
	})
</script>
<style src="../../../../css/crm/allClient.css"></style>
<style src="../../../../css/crm/crm_moreInfo_editor.css"></style>
<style lang="less">
	.connectInfo .el-dialog {
		width: 450px;
	}
	
	.crm-content.link-content {
		/*overflow-y: auto;*/
		.crm-content-title {
			border-bottom: 0;
		}
		.crm-table {
			.cus_bg {
				display: inline-block;
				width: 30px;
				height: 30px;
				vertical-align: middle;
			}
			.cus_info {
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				.cus_name {
					color: #20a0ff;
					cursor: pointer;
				}
				.cus_email {
					cursor: pointer;
					max-width: 240px;
				    overflow: hidden;
				    text-overflow: ellipsis;
				    white-space: nowrap;
				}
			}
			.td {
				.spot {
					display: inline-block;
					width: 9px;
					height: 9px;
					background-color: red;
					border-radius: 50%;
					cursor: pointer;
				}
				.deleteIcon {
					display: inline-block;
					width: 16px;
					height: 16px;
					cursor: pointer;
					vertical-align: middle;
				}
				.locationIcon {
					cursor: pointer;
					display: inline-block;
					width: 12px;
					height: 15px;
					/*background:url('../../../../../static/img/crm_images/icon/location.png') no-repeat;*/
					margin-left: 5px;
					vertical-align: middle;
				}
				.editIcon {
					.deleteIcon;
					background-position: -20px 0;
				}
				.addIcon {
					.deleteIcon;
					background-position: 0 0;
				}
			}
		}
	}
</style>
<style scoped="">
	.newTableStyle {
		/* position: absolute;
		top: 42px;
		bottom: 50px; */
		overflow: auto;
	}
</style>