<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·添加新客户弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="createClient">
    <div class="createTab">
      <el-dialog
        title="添加新客户"
        class='yourName create-link dialogOne'
        size="tiny"
        :visible.sync="test"
        :before-close="close"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false">
        <popupLoading></popupLoading>
        <div class="crm-create-box">
          <div class="ct-con-box">
          	<!-- 新建为客户或者转为客户联系人单选按钮 -->
            <div class="createTab-title">
              <ul>
                <li v-for="(item,index) in lists" @click="selectTitle(item,index)" :class="[titleNum === index?'select-active':'unselect-active']">
                  <el-radio class="radio" v-model="titleNum" :label="index">{{item.text}}</el-radio>
                </li>
              </ul>
            </div>
          </div>
          <div class="crm-alert-tab">
            <component :is="currentTab" @okReturn="okReturn" :linkId="linkId"  :strangeMail='strangeMail' :strangeName='strangeName' ref="tab" keep-alive></component>
          </div>
        </div>
        <div slot="footer" class="dialog-footer" >
          <el-button type="primary"  @click="submitCustomer">确 定</el-button>
          <el-button  @click="close">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import searchClient from './common_changeToLinkMan.vue' //转为客户联系人
  import newClientByLink from '../../createClient/createClient_newClientByLink.vue' //新建为客户
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import popupLoading from '@/components/publicComponent/popupLoading'
  export default ({
    name:'createClient',
    data(){
      return {
        test:true,
        currentTab:'tab1',
        titleNum:1,
        lists:[
          {
            text:"转为客户联系人"
          },{
            text:"新建为客户"
          }
        ],
      }
    },
    components:{
      tab1:searchClient,
      tab2:newClientByLink,
      popupLoading
    },
    props:[
	    'linkId','strangeMail','strangeName','typeJudge'
	  ],
    methods:{
    	//选择新建为客户或者转为客户联系人
      selectTitle:function(i,v){
        this.titleNum = v;
        this.currentTab = 'tab'+(v+1)
      },
      //触发父组件关闭弹窗
      close:function(){
        this.$emit('close',false);
      },
      //确定提交
      submitCustomer(){
      	if(this.titleNum==0){//转为客户联系人
      		this.$refs.tab.submitAdd();
      	}else{//添加为客户
      		this.$refs.tab.addByLink();
      	}
      },
      //提交返回
      okReturn(type,id){
      	this.$emit('successReturn',type,id);
      }
    },
    mounted(){
    	if(this.typeJudge=='search'){
  			this.titleNum = 0
  			this.currentTab = 'tab1';
      }else{
      	this.currentTab = 'tab2';
      }
      function clearTransform(){
        $('.el-dialog').css({
          'transform': 'none',
        })
      };
    
    
      
      
      dialogDrag();
      dialogCenter();
    }
  })
</script>
<style lang="less">
  *{
    margin:0;
    padding:0;
  }
  .red{
    color:red;
  }
  #createClient{
    color:#444;
  }
  .cnt-head{
    margin-bottom:10px;
    overflow: hidden;
    line-height: 14px;
    font-size:14px;

    background-color: #20A0FF;
    padding: 10px 10px;
    text-align: left;
    color:#fff;
  .el-icon-close{
    float:right;
    cursor:pointer;
    font-size:14px;

  }
  }
  #createClient .ct-con-box{
    padding:5px 5px 5px 0px;
  }
  #createClient .crm-alert-tab{
    height:368px;
  }
  #createClient .createTab-title{
    overflow:hidden;
    margin-bottom:10px;
    li{
      line-height:20px;
      cursor:pointer;
      float:left;
      padding: 6px 18px;
      box-sizing: border-box;
      border:1px solid #bfcbd9;
      border-right:0;

      background: #fff;
      color: #1f2d3d;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      cursor: pointer;
      transition: all .3s cubic-bezier(.645,.045,.355,1);
      font-size: 14px;

      &:nth-child(1){
         border-radius:2px 0 0 2px;
       }
      &:last-child{
         border-right:1px solid #bfcbd9;
         border-radius:0px 2px 2px 0px;
       }
    }
    .select-active{
      border-color:#20a0ff;
      background-color:#20a0ff;
      .radio{
        color:#fff;
      }
      .el-radio__input.is-checked .el-radio__inner{
        border-color: #fff;
        background: #fff;
      }
      .el-radio__inner::after{
        background: #20a0ff;
      }
    }
    .unselect-active{
      &:hover{
        .select-active
       }
    }
  }
   #createClient .crm-create-box{
    padding:0px 20px 10px;
    .cu_tbg{
      width:176px;
    }
  }
  .createTab{
  .createTab-content{
  .cu_tbcs{
  td{
    height:36px;
    line-height: 36px;
    text-align:left;
    padding-left:10px;
  .open-w{
    user-select: none;
    color:rgb(50, 174, 229);
    cursor:pointer;
  }
  input{
  .custx
  }
  .custx{
    border: 1px solid #bfcbd9;
    height: 30px;
    line-height: 30px;
    border-radius:2px;
    width: 180px;
  }
  }
  .cu-empty{
    height:5px;
    line-height: 35px;
    border-top:1px solid #ddd;
  }
  .cu_tbg{
    text-align:right;
    padding-right:10px;
  }
  }
  }
  .dialog-footer{
    float:right;
    margin-right:20px;
    margin-bottom:10px;
  }
  }
  .dialog-button{
    text-align: right;
    margin-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;

    padding-right: 6px;
  .dialog-cancel{
    border: 1px solid #999;
    height: 24px;
    line-height: 22px;
    color: #666;
    cursor: pointer;
    padding: 0 5px;
    margin: 0px;
    outline: none;
  }
  }
  #createClient{
    .createTab{
      .create-link{
        .el-dialog{
           width:1000px;
           height:auto;
         }
      }
    }
  }
  
#createClient{
    .createTab{
      .create-link{
       .el-switch{
			float: right;
		}
      }
    }
  }
</style>
