<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·转为客户联系人的页面
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
	<div>
		<!-- 搜索 -->
		<div class="searchHead">
			<!--  <label>请选择要转给的客户:</label>
      <select class="clientName" v-model="selectInfo">
      	<option value="">选择客户</option>
        <option :value="option.info" v-for="option in selectList">{{option.name}}</option>
      </select>-->
			<input v-model="searchInput" class="search-input" type="text" placeholder="请输入客户相关的信息..." />
			<span @click="searchClient" class="search-btn">搜索</span>
			<span @click="reset_loadAllCusInfo" class="search-btn">重置</span>
		</div>
		<!-- 表格 -->
		<div class="search-result">
			<table width="100%" cellspacing="0" v-loading="autographLoading" element-loading-text="拼命加载中">
				<thead>
					<tr>
						<th width="25%">企业信息</th>
						<th width="25%">邮件地址</th>
						<th width="15%">入库时间</th>
						<th width="15%">最后联系时间</th>
						<th width="15%">选择</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="row in cusInfoList">
						<td>
							<div class="maxadd-width1 hide-name">
								<el-tooltip class="item" effect="dark" :open-delay="300" :content="row.customer_name" placement="top">
									<span>{{row.customer_name}}</span>
								</el-tooltip>
							</div>
						</td>
						<td>
							<div class="maxadd-width1 hide-name">
								<el-tooltip class="item" effect="dark" :open-delay="300" :content="row.email" placement="top">
									<span>{{row.email}}</span>
								</el-tooltip>
							</div>
						</td>
						<td>
							<el-tooltip class="item" effect="dark" :open-delay="300" :content="row.enrol_time" placement="top">
								<span>{{row.enrol_date}}</span>
							</el-tooltip>
						</td>
						<td>
							<el-tooltip class="item" effect="dark" :open-delay="300" :content="row.contact_time" placement="top">
								<span>{{row.contact_date}}</span>
							</el-tooltip>
						</td>
						<td>
							<el-radio-group v-model="searchInfo">
								<el-radio class="radio2" :label="row.info"></el-radio>
							</el-radio-group>
						</td>
					</tr>
					<tr v-if="cusInfoList.length == 0">
						<td colspan="100%" class="noData-box">
							<div class="noData-bg">
							</div>
							<span class="noData">暂无数据</span>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- 分页 -->
			<div style="text-align: center;padding-top: 10px;">
				<el-pagination v-if="cusInfoList.length > 0" :current-page="cus_currpage" :page-size="cus_pagesize" layout="total,sizes,prev, pager, next, jumper" :total="cus_tolRecord" @current-change="cus_changeCurrentPage" @size-change="cus_changeSizePage">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
	export default({
		props: [
			'linkId', 'strangeMail', 'strangeName',
		],
		data() {
			return {
				selectInfo: '', //下拉选择的客户id
				searchInfo: '', //搜索的客户id
				searchInput: '', //搜索信息
				searchList: [],
				selectList: [],
				autographLoading: false, //加载框
				linkMan: {}, //联系人实体
				cus_currpage: 1, //当前页
				cus_pagesize: 10, //一页显示的数量
				cus_tolRecord: 1, //总量
				cus_condition:0,//条件
				cus_conditionValue:0,//条件值
				cusInfoList: [], //客户信息集合
				emailTrue:false,
			}
		},
		computed: {
			...mapState({
				userinfo: state => state.userinfo,
				loadCusNumTag: state => state.loadCusNumTag,
				userlistall: state => state.userlistall,
				companyparamlist: state => state.companyparamlist,
			}),
		},
		methods: {
			...mapMutations({
				getUserlistAll: 'getUserlistAll',
			}),
			//获取联系人
			getLinkman() {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "link_findByLinkid.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						linkmanid: lef.linkId
					},
					success: function(msg) {
						lef.linkMan = msg;
					}
				});
			},
			//重置信息
			reset_loadAllCusInfo(){
				this.searchInput = '';
				this.cus_pagesize = 10;
				this.cus_currpage = 1;
				this.cus_condition = 0;
				this.cus_conditionValue = 0;
				this.loadAllCusInfo();
			},
			//加载所有客户信息
			loadAllCusInfo() {
				let _this = this;
				_this.cusInfoList = [];
				$.ajax({
					type: "POST",
					url: backUrl + "cus_findValueCustomer.action",
					async: true,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						pagesize: _this.cus_pagesize,
						currpage: _this.cus_currpage,
						condition: _this.cus_condition,
						value: _this.cus_conditionValue,
						select_type: 0,
						select_value: 0
					},
					beforeSend: function() {
						_this.autographLoading = true;
					},
					complete: function() {
						_this.autographLoading = false;
					},
					success: function(msg) {
						_this.cus_tolRecord = msg.pageBean.totalRecord; //总条数
						$.each(msg.crmcustomerlist, function(index, row) {
							//入库时间
							var enrol_date = row.enrol_time.split(" ")[0];
							var enrol_time = row.enrol_time.split(" ")[1].replace(".0","");
							//最后联系时间
							var contact_date = "暂无活跃";
							var contact_time = "";
							if(row.last_contact_date != null && row.last_contact_date != '') {
								contact_date = row.last_contact_date.split(" ")[0];
								contact_time = row.last_contact_date.split(" ")[1];
							}
							_this.cusInfoList.push({
								customer_name: row.customer_name,
								email: row.email,
								enrol_date: enrol_date,
								enrol_time: enrol_time,
								contact_date: contact_date,
								contact_time: contact_time,
								"info": row.customer_id + "###" + row.classify_id,
								classify_id:row.classify_id,
								
							})
						})
					}
				});
			},
			//改变页数
			cus_changeCurrentPage(page) {
				this.searchInfo = ''
				this.cus_pagesize = 10;
				this.cus_currpage = page;
				this.loadAllCusInfo();
			},
			//改变条数
			cus_changeSizePage(size) {
				this.searchInfo = ''
				this.cus_currpage = 1;
				this.cus_pagesize = size;
				this.loadAllCusInfo();
			},

			//搜索客户
			searchClient() {
				if(this.searchInput.trim() == '') {
					this.$message({
						showClose: true,
						message: '请输入客户的相关信息！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				this.searchInfo = ''
				this.cus_currpage = 1;
				this.cus_pagesize = 10;
				this.cus_condition = 33;
				this.cus_conditionValue = this.searchInput.trim();
				this.loadAllCusInfo();
			},
			//提交
			submitAdd() {
				if(this.searchInfo == '' && this.selectInfo == '') {
					this.$message({
						showClose: true,
						message: '请选择客户！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				var cus_id = 0;
				var type_id = 0;
				
				if(this.searchInfo != '') {
					cus_id = this.searchInfo.split('###')[0];
					type_id = this.searchInfo.split('###')[1];
				} else {
					cus_id = this.selectInfo.split('###')[0];
					type_id = this.selectInfo.split('###')[1];
				}
				var verifystr = cus_id;
				if(this.linkId != 0 && this.linkId != undefined && this.linkId != null) {
					verifystr = this.linkMan.email.trim()
				}
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "xcus_isCustomerEmailExist2.action",
					dataType: "json",
					cache: false,
//					async: false,
					data: {
						token: token,
						verifystr: this.strangeMail,
						classify_id: type_id,
						sysenablerepeat: lef.companyparamlist.param159
					},
					beforeSend: function() {
					lef.$store.state.popupLoading = true;
					},
						complete: function() {
							lef.$store.state.popupLoading = false;
						},
					success: function(msg) {
						if(msg.result == -1) { //不存在
							if(lef.linkId != 0 && lef.linkId != undefined && lef.linkId != null) {
								$.ajax({
									type: "POST",
									url: backUrl + "link_updateToCustomer.action",
									dataType: "json",
									cache: false,
									async: false,
									data: {
										token: token,
										linkmanid: lef.linkId,
										customerid: cus_id,
										classify_id:type_id,
										email:lef.linkMan.email
									},
									success: function(msg) {
										if(msg > 0) {
											lef.$message({
												showClose: true,
												message: '转为客户联系人成功！',
												type: 'success',
												duration: 2000
											});
											lef.$emit('okReturn', false, msg);
										} else {
											lef.$message({
												showClose: true,
												message: '转为客户联系人失败！',
												type: 'warning',
												duration: 2000
											});
										}
									},
									error: function(e) {
										lef.$message({
											showClose: true,
											message: '转为客户联系人失败！',
											type: 'warning',
											duration: 2000
										});
									}
								});
							} else {
								$.ajax({
									type: "POST",
									url: backUrl + "link_updateToCustomerLinkMan.action",
									dataType: "json",
									cache: false,
									async: false,
									data: {
										token: token,
										customerid: verifystr,
										email: lef.strangeMail,
										full_name: lef.strangeName,
										classify_id:type_id
									},
									success: function(msg) {
										if(msg > 0) {
											lef.$message({
												showClose: true,
												message: '添加新用户成功，3分钟内邮件例表的图标将会改变！',
												type: 'success',
												duration: 2000
											});
											lef.$emit('okReturn', false, msg);
										} else {
											lef.$message({
												showClose: true,
												message: '转为客户联系人失败！',
												type: 'warning',
												duration: 2000
											});
										}
									},
									error: function(e) {
										lef.$message({
											showClose: true,
											message: '转为客户联系人失败！',
											type: 'warning',
											duration: 2000
										});
									}
								});
							}

						} else { //存在
							if(lef.userlistall == '') {
								lef.getUserlistAll();
							}
							var username = "其它同事";
							 if(msg.result==0){
									username='我'
								}else if(msg.result>0){
									$.each(lef.userlistall, function(index, user) {
									if(msg.result == user.user_id) {
										username = user.user_name;
										return false;
									}
									});
								}
							let tion=''
							if(msg.type==0){
									tion='正常库'
								}else if(msg.type==1){
									tion='公海'
								}else if(msg.type==2){
									tion='已删除'
								}
							lef.$message({
								showClose: true,
								message: '该邮件地址对应的客户已存在于 [' + username + '] 的客户中，位置 ['+tion+']',
								type: 'warning',
								duration: 2000
							});
						}
					},
				});
			}
		},
		watch: {
			selectInfo() {
				this.searchInput = '';
				this.searchInfo = '';
				this.cusInfoList = [];
			},
		},
		mounted() {
			this.loadAllCusInfo();//加载所有客户信息
			this.getLinkman();//获取联系人
		}
	})
</script>
<style lang="less">
	.search-result {
		margin-top: 10px;
		th,
		td {
			text-align: center;
			font-weight: normal;
		}
		th {
			background-color: #ededed;
		border-bottom: 1px solid #ededed;
			height: 30px;
		}
		td {
			height: 50px;
			border-bottom: 1px solid #ededed;
			.el-radio__label {
				display: none;
			}
		}
	}	
	.maxadd-width1 {
		max-width: 220px;
	}
	
	.hide-name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.searchHead {
		.clientName,
		.search-input {
			width: 250px;
			height: 30px;
			vertical-align: middle;
			border: 1px solid #ddd;
		}
		.search-btn {
			cursor: pointer;
			display: inline-block;
			height: 30px;
			line-height: 30px;
			color: #fff;
			background-color: #20a0ff;
			vertical-align: middle;
			width: 50px;
			text-align: center;
		}
		.search-btn:hover {
			background: #4db3ff;
			border-color: #4db3ff;
			color: #fff;
		}
	}
</style>