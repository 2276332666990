<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·添加新客户·联系人转为客户弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<!--联系人转为客户-->
<template>
  <div id="newClientByhand" class="createTab-content">
    <form method="post" action id="link_customerinfoForm">
      <input type="hidden" name="crmlinkman.linkman_id" :value="linkId" />
      <table width="100%" cellpadding="0" cellspacing="0" class="cu_tbcs">
        <tbody>
          <tr>
            <td class="ft2 cu-t">
              <b>
                <span>基本信息</span>
              </b>
            </td>
            <td class="cu-t" colspan="3"></td>
          </tr>
          <tr>
            <td class="cu-empty" colspan="4"></td>
          </tr>
          <tr>
            <td class="cu_tbg">
              <span>企业全称</span>
            </td>
            <td>
              <input
                type="text"
                class="custx"
                @blur="cusname_varify(0)"
                v-model="cus_name"
                name="crmcustomer2.customer_name"
              />
              <span class="red" title="必填项" style="cursor: pointer;">*</span>
              <span
                  v-show="hasVertify1"
                  :title="correct1?correctTitle:errorTitle1"
                  class="rightError"
                  :class="correct1?'right-span':'error-span'"
                ></span>
            </td>
            <td class="cu_tbg">
              <span>联系人姓名</span>
            </td>
            <td>
              <input
                type="text"
                class="custx"
                @blur="linkname_verify()"
                v-model="link_name"
                name="crmlinkman.full_name"
              />
              <span class="red" title="必填项" style="cursor: pointer;">*</span>
              <span
                v-show="hasVertify2"
                :title="correct2?correctTitle:errorTitle2"
                class="rightError"
                :class="correct2?'right-span':'error-span'"
              ></span>
            </td>
          </tr>
          <tr>
            <td class="cu_tbg">
              <span>所在国家</span>
            </td>
            <td style="position:relative">
              <all-country></all-country>
            </td>
            <td class="cu_tbg">
              <span>联系人邮箱</span>
            </td>
            <td>
              <input
                type="text"
                class="custx"
                @blur="email_verify(0)"
                v-model="email"
                name="crmcustomer2.email"
              />
              <span class="red" title="必填项" style="cursor: pointer;">*</span>
                <span
                  v-show="hasVertify4"
                  :title="correct4?correctTitle:errorTitle4"
                  class="rightError"
                  :class="correct4?'right-span':'error-span'"
                ></span>
            </td>
          </tr>
          <tr>
            <td class="cu_tbg">
              <span>首次联系</span>
            </td>
            <td>
              <div class="block custx hasDatepicker">
                <el-date-picker
                  v-model="dateValue"
                  type="date"
                  placeholder="选择日期时间"
                  :editable="false"
                  :picker-options="pickerOptions0"
                ></el-date-picker>
              </div>
            </td>
            <td class="cu_tbg">
              <span>联系人电话</span>
            </td>
            <td>
              <input
                type="text"
                class="custx"
                id="cusM_mobile"
                v-model="phone"
                name="crmcustomer2.phone"
              />
              <span class="red" v-show="isShow2" title="必填项" style="cursor: pointer;">*</span>
              <i
                @click="showT = !showT"
                :class="[showT?'unfold':'fold']"
                :title="!showT?'添加多个联系电话':'删除多个联系电话'"
              ></i>
              <span
                v-show="hasVertify5"
                :title="correct5?correctTitle:errorTitle5"
                class="rightError"
                :class="correct5?'right-span':'error-span'"
              ></span>
            </td>
          </tr>
          <tr v-if="showT">
            <td class="cu_tbg">
              <span>联系人电话1</span>
            </td>
            <td>
              <input type="text" class="custx" id="cus_phone1" />
            </td>
            <td class="cu_tbg">
              <span>联系人电话2</span>
            </td>
            <td>
              <input type="text" class="custx" id="cus_phone2" />
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td class="ft2 cu-t">
              <span>
                <b>业务信息</b>
              </span>
            </td>
            <td class="cu-t" colspan="3"></td>
          </tr>
          <tr>
            <td class="cu-empty" colspan="4"></td>
          </tr>
          <tr>
            <td class="cu_tbg">
              <span>客户分类</span>
            </td>
            <td>
              <div>
                <select id="classify_select" class="custx" name="crmcustomer2.classify_id">
                  <option
                    v-for="classify in classifylist"
                    :value="classify.id"
                    :title="classify.name"
                  >{{classify.name1}}</option>
                </select>
              </div>
            </td>
            <td class="cu_tbg">
              <span>客户来源</span>
            </td>
            <td>
              <div>
                <select id="source_select" class="custx" name="crmcustomer2.source_from_id">
                  <option
                    v-for="source in sourcelist"
                    :value="source.id"
                    :title="source.name"
                  >{{source.name1}}</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td class="cu_tbg">
              <span>客户关系</span>
            </td>
            <td>
              <div>
                <select id="relation_select" class="custx" name="crmcustomer2.partnership">
                  <option
                    v-for="relation in relationlist"
                    :value="relation.id"
                    :title="relation.name"
                  >{{relation.name1}}</option>
                </select>
              </div>
            </td>
            <td class="cu_tbg">
              <span>客户状态</span>
            </td>
            <td>
              <div>
                <select id="status_select" class="custx" name="crmcustomer2.customer_status">
                  <option
                    v-for="status in statuslist"
                    :value="status.id"
                    :title="status.name"
                  >{{status.name1}}</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td class="cu_tbg">
              <span>所属行业</span>
            </td>
            <td>
              <div>
                <select id="hangye_select" class="custx" name="crmcustomer2.calling">
                  <option
                    v-for="hangye in hangyelist"
                    :value="hangye.cid"
                    :title="hangye.cname"
                  >{{hangye.name}}</option>
                </select>
              </div>
            </td>
            <td class="cu_tbg">
              <span>关联产品</span>
            </td>
            <td>
              <com-product :choiceProductArr="productid" @cofirmProduct="pcpData"></com-product>
              <!-- <productClassPublic @pcpData="pcpData" name="crmcustomer2.prductid"></productClassPublic> -->
              <!--<select id="product_select" class="custx" name="crmcustomer2.prductid" v-show='false'>
									<option v-for="product in productlist" :value="product.id" :title="product.name">{{product.name1}}</option>
              </select>-->
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td class="ft2 cu-t cu-info" colspan="100%">
              <span>
                <b>自定义字段</b>
              </span>
              <el-switch class="crm-witch" v-model="showF" on-text="展开" off-text="收缩"></el-switch>
            </td>
            <td class="cu-t" colspan="3"></td>
          </tr>
          <tr>
            <td class="cu-empty" colspan="4"></td>
          </tr>
          <tr class="link_myfield" v-show="showF">
            <td class="cu_tbg">
              <span
                class="fold"
                @click="addfield($event)"
                title="添加自定义字段"
                style="margin-left: 5px;"
              ></span>
            </td>
            <td class="cu-t" colspan="3"></td>
          </tr>
        </tbody>

        <tbody class="re-info">
          <tr>
            <td class="ft2 cu-t cu-info" colspan="100%">
              <span>
                <b>其它信息</b>
              </span>
              <el-switch class="crm-witch" v-model="showW" on-text="展开" off-text="收缩"></el-switch>
            </td>
            <td class="cu-t" colspan="3"></td>
          </tr>
          <tr>
            <td class="cu-empty" colspan="4"></td>
          </tr>
          <tr v-show="showW">
            <td class="cu_tbg">
              <span>省份/州/区</span>
            </td>
            <td>
              <input
                type="text"
                class="custx"
                v-model="province"
                id="cusM_province"
                name="crmcustomer2.province"
              />
              <span class="red" v-show="isShow3" title="必填项" style="cursor: pointer;">*</span>
              <span
                v-show="hasVertify6"
                :title="correct6?correctTitle:errorTitle6"
                class="rightError"
                :class="correct6?'right-span':'error-span'"
              ></span>
            </td>
            <td class="cu_tbg">
              <span>城市</span>
            </td>
            <td>
              <input
                type="text"
                class="custx"
                v-model="city"
                id="cusM_city"
                name="crmcustomer2.city"
              />
              <span class="red" v-show="isShow4" title="必填项" style="cursor: pointer;">*</span>
              <span
                v-show="hasVertify7"
                :title="correct7?correctTitle:errorTitle7"
                class="rightError"
                :class="correct7?'right-span':'error-span'"
              ></span>
            </td>
          </tr>
          <tr v-show="showW">
            <td class="cu_tbg">
              <span>企业简称</span>
            </td>
            <td>
              <input
                type="text"
                class="custx"
                v-model="sname"
                id="cusM_tomersimp"
                name="crmcustomer2.simp_name"
              />
              <span class="red" v-show="isShow5" title="必填项" style="cursor: pointer;">*</span>
              <span
                v-show="hasVertify8"
                :title="correct8?correctTitle:errorTitle8"
                class="rightError"
                :class="correct8?'right-span':'error-span'"
              ></span>
            </td>
            <td class="cu_tbg">
              <span>员工规模</span>
            </td>
            <td>
              <select class="custx" id="cusM_persons_scale" name="crmcustomer2.persons_scale">
                <option value="1-50">1-50</option>
                <option value="50-500">50-500</option>
                <option value="500-1000">500-1000</option>
                <option value="1000以上">1000以上</option>
              </select>
            </td>
          </tr>
          <tr v-show="showW">
            <td class="cu_tbg">
              <span>手机</span>
            </td>
            <td>
              <input
                type="text"
                class="custx"
                v-model="mobile"
                name="crmcustomer2.mobile"
                id="cusM_mobilephone"
              />
              <span class="red" v-show="isShow6" title="必填项" style="cursor: pointer;">*</span>
              <span
                v-show="hasVertify9"
                :title="correct9?correctTitle:errorTitle9"
                class="rightError"
                :class="correct9?'right-span':'error-span'"
              ></span>
            </td>
            <td class="cu_tbg">
              <span>职位</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="post" name="crmlinkman.post" id="cusM_post" />
              <span class="red" v-show="isShow7" title="必填项" style="cursor: pointer;">*</span>
              <span
                v-show="hasVertify10"
                :title="correct10?correctTitle:errorTitle10"
                class="rightError"
                :class="correct10?'right-span':'error-span'"
              ></span>
            </td>
          </tr>
          <tr v-show="showW">
            <td class="cu_tbg">
              <span>传真</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="fax" name="crmcustomer2.fax" id="cusM_fax" />
              <span class="red" v-show="isShow8" title="必填项" style="cursor: pointer;">*</span>
              <span
                v-show="hasVertify11"
                :title="correct11?correctTitle:errorTitle11"
                class="rightError"
                :class="correct11?'right-span':'error-span'"
              ></span>
            </td>
            <td class="cu_tbg">
              <span>网址</span>
            </td>
            <td>
              <input
                type="text"
                class="custx"
                v-model="web"
                name="crmcustomer2.website"
                id="cusM_website"
              />
              <span class="red" v-show="isShow9" title="必填项" style="cursor: pointer;">*</span>
              <span
                v-show="hasVertify12"
                :title="correct12?correctTitle:errorTitle12"
                class="rightError"
                :class="correct12?'right-span':'error-span'"
              ></span>
            </td>
          </tr>
          <tr v-show="showW">
            <td class="cu_tbg">
              <span>地址</span>
            </td>
            <td colspan="3">
              <input
                type="text"
                class="custx"
                v-model="address"
                style="width: 620px;"
                name="crmcustomer2.address"
                id="cusM_address"
              />
              <span class="red" v-show="isShow10" title="必填项" style="cursor: pointer;">*</span>
              <span
                v-show="hasVertify13"
                :title="correct13?correctTitle:errorTitle13"
                class="rightError"
                :class="correct13?'right-span':'error-span'"
              ></span>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td class="ft2 cu-t" colspan="100%">
              <b>
                <span>社交信息</span>
              </b>
              <el-switch class="crm-witch" v-model="showS" on-text="展开" off-text="收缩"></el-switch>
              <!--<span id="cus_span_id2" class="open-w" @click="showS = !showS">{{showS?'收缩':'展开'}}</span>-->
            </td>
            <td class="cu-t" colspan="3"></td>
          </tr>
          <tr>
            <td class="cu-empty" colspan="4"></td>
          </tr>
          <tr v-show="showS">
            <td class="cu_tbg">
              <span>Facebook</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="facebook" name="crmcustomer2.facebook" />
            </td>
            <td class="cu_tbg">
              <span>Twitter</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="twitter" name="crmcustomer2.twitter" />
            </td>
          </tr>
          <tr v-show="showS">
            <td class="cu_tbg">
              <span>LinkedIn</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="linkedin" name="crmcustomer2.linkedin" />
            </td>
            <td class="cu_tbg">
              <span>Pinterest</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="pinterest" name="crmcustomer2.pinterest" />
            </td>
          </tr>
          <tr v-show="showS">
            <td class="cu_tbg">
              <span>Google+</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="google" name="crmcustomer2.google" />
            </td>
            <td class="cu_tbg">
              <span>Tumblr</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="tumblr" name="crmcustomer2.tumblr" />
            </td>
          </tr>
          <tr v-show="showS">
            <td class="cu_tbg">
              <span>Instagram</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="instagram" name="crmcustomer2.instagram" />
            </td>
            <td class="cu_tbg">
              <span>VK</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="vk" name="crmcustomer2.vk" />
            </td>
          </tr>
          <tr v-show="showS">
            <td class="cu_tbg">
              <span>Made In China</span>
            </td>
            <td>
              <input
                type="text"
                class="custx"
                v-model="make_in_china"
                name="crmcustomer2.make_in_china"
              />
            </td>
            <td class="cu_tbg">
              <span>Alibaba</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="alibaba" name="crmcustomer2.alibaba" />
            </td>
          </tr>
          <tr v-show="showS">
            <td class="cu_tbg">
              <span>Skype</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="skype" name="crmcustomer2.skype" />
            </td>
            <td class="cu_tbg">
              <span>Youtube</span>
            </td>
            <td>
              <input type="text" class="custx" v-model="youtube" name="crmcustomer2.youtube" />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
    <div class="crmPopupLoading" v-if='crmPopupLoading || companyLoad || emailLoad'>
      <div class="plMain">
        <i class="el-icon-loading plIcon"></i>
        <p class="plTip">{{loadingTitle}}</p>
      </div>
	  </div>
  </div>
</template>
<script>
import Vue from "vue";
import store from "@/vuex/store";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import productClassPublic from "@/components/productClassPublic";
import allCountry from "@/components/crm/country/country";
import comProduct from "@/components/common_file/com_product/comProduct";
export default {
  name: "newClientByhand",
  data() {
    return {
      //必填项的显示隐藏标识
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      isShow5: false,
      isShow6: false,
      isShow7: false,
      isShow8: false,
      isShow9: false,
      isShow10: false,
      //各验证显示隐藏标识
      hasVertify1: false,
      correct1: false,
      errorTitle1: "",
      cus_name: "",
      companyLoad: false,

      hasVertify2: false,
      correct2: false,
      errorTitle2: "",
      link_name: "",

      hasVertify3: false,
      correct3: false,
      errorTitle3: "",
      country: "",

      hasVertify4: false,
      correct4: false,
      errorTitle4: "",
      email: "",
      emailLoad: false,

      hasVertify5: false,
      correct5: false,
      errorTitle5: "",
      phone: "",

      hasVertify6: false,
      correct6: false,
      errorTitle6: "",
      province: "",

      hasVertify7: false,
      correct7: false,
      errorTitle7: "",
      city: "",

      hasVertify8: false,
      correct8: false,
      errorTitle8: "",
      sname: "",

      hasVertify9: false,
      correct9: false,
      errorTitle9: "",
      mobile: "",

      hasVertify10: false,
      correct10: false,
      errorTitle10: "",
      post: "",

      hasVertify11: false,
      correct11: false,
      errorTitle11: "",
      fax: "",

      hasVertify12: false,
      correct12: false,
      errorTitle12: "",
      web: "",

      hasVertify13: false,
      correct13: false,
      errorTitle13: "",
      address: "",

      showT: false,
      showW: false,
      showS: false,
      showF: false, //自定义
      correct: false,

      correctTitle: "恭喜，输入正确！",

      valCountry: "",
      pickerOptions1: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          }
        ]
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      dateValue: "", //首次联系
      classifylist: [], //客户分类
      sourcelist: [], //客户来源
      relationlist: [], //客户关系
      statuslist: [], //客户状态
      hangyelist: [], //所属行业
      productlist: [], //关联产品

      facebook: "",
      twitter: "",
      linkedin: "",
      pinterest: "",
      google: "",
      tumblr: "",
      instagram: "",
      vk: "",
      make_in_china: "",
      alibaba: "",
      skype: "",
      youtube: "",
      productid:'',
	  counts: 0 ,//自定义添加
	  companyTure:false,
    emailTrue:false,
    loadingTitle:'',//当前正在验证title
      crmPopupLoading:false,//当前crm的loading弹框
      confirmLoad:false,//当前是否正在雁阵
    };
  },
  components: {
    productClassPublic,
    allCountry,
    comProduct
  },
  methods: {
    ...mapMutations({
      getCustomerClassifyAll: "getCustomerClassifyAll",
      getCustomerFromAll: "getCustomerFromAll",
      getCustomerRelationAll: "getCustomerRelationAll",
      getCustomerStatusAll: "getCustomerStatusAll",
      getIndustryType: "getIndustryType",
      getMajorProductAll: "getMajorProductAll",
      getNecessaryitemAll: "getNecessaryitemAll",
      getUserlistAll: "getUserlistAll"
    }),
    //选择的关联产品
    pcpData(data) {
      this.productid = data;
    },
    //获取联系人
    getLinkman() {
      var lef = this;
      $.ajax({
        type: "POST",
        url: backUrl + "link_findByLinkid.action",
        async: false,
        cache: false,
        dataType: "json",
        data: {
          token: token,
          linkmanid: lef.linkId
        },
        success: function(msg) {
          lef.linkMan = msg;
          lef.link_name = lef.linkMan.full_name;
          lef.email = lef.linkMan.email;
          lef.phone = lef.linkMan.phone;
        }
      });
    },
    //处理客户分类
    handleClassify() {
      if (this.customerclassifyall == "") {
        this.getCustomerClassifyAll();
      }
      //				this.classifylist.push({
      //					name: "请选择",
      //					name1: "请选择",
      //					id: 0
      //				});
      var lef = this;
      $.each(this.customerclassifyall, function(index, row) {
        var name1 = row.name;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        lef.classifylist.push({
          name: row.name,
          name1: name1,
          id: row.id
        });
      });
    },
    //处理客户来源
    handleSourcefrom() {
      if (this.customerfromall == "") {
        this.getCustomerFromAll();
      }
      //				this.sourcelist.push({
      //					name: "请选择",
      //					name1: "请选择",
      //					id: 0
      //				});
      var lef = this;
      $.each(this.customerfromall, function(index, row) {
        var name1 = row.name;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        lef.sourcelist.push({
          name: row.name,
          name1: name1,
          id: row.id
        });
      });
    },
    //处理客户关系
    handleRelation() {
      if (this.customerrelationall == "") {
        this.getCustomerRelationAll();
      }
      //				this.relationlist.push({
      //					name: "请选择",
      //					name1: "请选择",
      //					id: 0
      //				});
      var lef = this;
      $.each(this.customerrelationall, function(index, row) {
        var name1 = row.name;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        lef.relationlist.push({
          name: row.name,
          name1: name1,
          id: row.id
        });
      });
    },
    //处理客户状态
    handleStatus() {
      if (this.customerstatusall == "") {
        this.getCustomerStatusAll();
      }
      //				this.statuslist.push({
      //					name: "请选择",
      //					name1: "请选择",
      //					id: 0
      //				});
      var lef = this;
      $.each(this.customerstatusall, function(index, row) {
        var name1 = row.name;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        lef.statuslist.push({
          name: row.name,
          name1: name1,
          id: row.id
        });
      });
    },
    //处理所属行业
    handleYanghe() {
      var lef = this;
      $.each(this.industryType, function(index, row) {
        var name = row.cname;
        if (name.length > 12) {
          name = name.substring(0, 12) + "...";
        }
        lef.hangyelist.push({
          cname: row.cname,
          name: name,
          cid: row.cid
        });
      });
    },
    //处理关联产品
    handleProduct() {
      if (this.majorproductall == "") {
        this.getMajorProductAll();
      }
      //				this.productlist.push({
      //					name: "请选择",
      //					name1: "请选择",
      //					id: 0
      //				});
      var lef = this;
      $.each(this.majorproductall, function(index, row) {
        var name1 = row.name;
        if (name1.length > 12) {
          name1 = name1.substring(0, 12) + "...";
        }
        lef.productlist.push({
          name: row.name,
          name1: name1,
          id: row.id
        });
      });
    },
    //处理必填项
    handleNecessary() {
      //加载必填项信息
      if (this.necessaryitemall == "") {
        this.getNecessaryitemAll();
      }
      //无必填项则不展示其它信息
      if (this.necessaryitemall.length == 0) {
        this.showW = false;
      } else {
        //有必填项则展示其它信息
        var lef = this;
        $.each(this.necessaryitemall, function(index, row) {
          if (row.necessary_item.trim() == "所在国家") {
            lef.isShow1 = true;
            $("#cusM_Country").blur(function() {
              lef.country_verify();
            });
          } else if (row.necessary_item.trim() == "联系人电话") {
            lef.isShow2 = true;
            $("#cusM_mobile").blur(function() {
              lef.phone_verify();
            });
          } else if (row.necessary_item.trim() == "省份/州/区") {
            lef.isShow3 = true;
            $("#cusM_province").blur(function() {
              lef.province_verify();
            });
          } else if (row.necessary_item.trim() == "城市") {
            lef.isShow4 = true;
            $("#cusM_city").blur(function() {
              lef.city_verify();
            });
          } else if (row.necessary_item.trim() == "企业简称") {
            lef.isShow5 = true;
            $("#cusM_tomersimp").blur(function() {
              lef.sname_verify();
            });
          } else if (row.necessary_item.trim() == "手机") {
            lef.isShow6 = true;
            $("#cusM_mobilephone").blur(function() {
              lef.mobile_verify();
            });
          } else if (row.necessary_item.trim() == "职位") {
            lef.isShow7 = true;
            $("#cusM_post").blur(function() {
              lef.post_verify();
            });
          } else if (row.necessary_item.trim() == "传真") {
            lef.isShow8 = true;
            $("#cusM_fax").blur(function() {
              lef.fax_verify();
            });
          } else if (row.necessary_item.trim() == "网址") {
            lef.isShow9 = true;
            $("#cusM_website").blur(function() {
              lef.web_verify();
            });
          } else if (row.necessary_item.trim() == "地址") {
            lef.isShow10 = true;
            $("#cusM_address").blur(function() {
              lef.address_verify();
            });
          }
        });
        //判断是否只有所在国家和联系电话为必填项
        if (
          !this.isShow3 &&
          !this.isShow4 &&
          !this.isShow5 &&
          !this.isShow6 &&
          !this.isShow7 &&
          !this.isShow8 &&
          !this.isShow9 &&
          !this.isShow10
        ) {
          this.showW = false;
        } else {
          this.showW = true;
        }
      }
    },
    //验证客户全称
    cusname_varify(type) {
      if (
        this.cus_name.trim().length < 4 ||
        this.cus_name.trim().length > 200
      ) {
        this.hasVertify1 = true;
        this.correct1 = false;
        this.errorTitle1 = "企业全称4~200个字符之间!";
         this.$message({
						type: "warning",
						message:this.errorTitle1,
						duration: 2000
						});
        return;
      }
      if(type==0){
        this.loadingTitle='正在验证企业全称，请稍后！'
      }
      var lef = this;
      $.ajax({
        type: "POST",
        url: backUrl + "xcus_isCustomerNameExist2.action",
        dataType: "json",
        cache: false,
        // async: false,
        data: {
          token: token,
          verifystr: lef.cus_name.trim(),
          classify_id: $("#classify_select").val(),
          sysenablerepeat: lef.companyparamlist.param159
        },
        beforeSend: function() {
          lef.companyLoad=true
        },
        complete: function() {
          lef.companyLoad=false
        },
        success: function(msg) {
          if (msg.result == -1) {
            //不存在
            lef.hasVertify1 = true;
            lef.correct1 = true;
          } else {
            //存在
            if (lef.userlistall == "") {
              lef.getUserlistAll();
            }
            var username = "其它同事";
           if(msg.result==0){
									username='我'
								}else if(msg.result>0){
									$.each(lef.userlistall, function(index, user) {
									if(msg.result == user.user_id) {
										username = user.user_name;
										return false;
									}
									});
								}
            lef.hasVertify1 = true;
            lef.correct1 = false;
            let tion = "";
            if (msg.type == 0) {
              tion = "正常库";
            } else if (msg.type == 1) {
              tion = "公海";
            } else if (msg.type == 2) {
              tion = "已删除";
            }
            lef.errorTitle1 =
              "该企业全称对应的客户已存在于 [" +
              username +
              " ]的客户中，位置[" +
              tion +
              "]";
            lef.$message({
              message:
                "该企业全称对应的客户已存在于 [" +
                username +
                " ]的客户中，位置[" +
                tion +
                "]",
              type: "warning"
            });
            lef.confirmLoad=false
          }
        },
        error: function(e) {
          lef.hasVertify1 = true;
          lef.correct1 = false;
          lef.errorTitle1 = "检查企业全称重复性网络异常，请刷新界面后重试！";
          lef.confirmLoad=false
        }
      });
    },
    //验证姓名
    linkname_verify() {
      if (
        this.link_name.trim().length < 2 ||
        this.link_name.trim().length > 50
      ) {
        this.hasVertify2 = true;
        this.correct2 = false;
        this.errorTitle2 = "联系人姓名2~50个字符之间!";
        return;
      }
      this.hasVertify2 = true;
      this.correct2 = true;
    },
    //验证邮箱
    email_verify(type) {
      var mailreg = /^\w+([\.\'-]?[\w+]?)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
      if (mailreg.test(this.email.trim())) {
        this.hasVertify4 = true;
        this.correct4 = true;
        var lef = this;
        if(type==0){
          this.loadingTitle='正在验证联系人邮箱，请稍后！'
        }
        $.ajax({
          type: "POST",
          url: backUrl + "xcus_isCustomerEmailExist2.action",
          dataType: "json",
          cache: false,
          // async: false,
          data: {
            token: token,
            verifystr: lef.email.trim(),
            classify_id: $("#classify_select").val(),
            sysenablerepeat: lef.companyparamlist.param159
          },
          beforeSend: function() {
          lef.emailLoad=true
        },
        complete: function() {
          lef.emailLoad=false
        },
          success: function(msg) {
            if (msg.result == -1) {
              //不存在
              lef.hasVertify4 = true;
              lef.correct4 = true;
            } else {
              //存在
              if (lef.userlistall == "") {
                lef.getUserlistAll();
              }
              var username = "其它同事";
               if(msg.result==0){
									username='我'
								}else if(msg.result>0){
									$.each(lef.userlistall, function(index, user) {
									if(msg.result == user.user_id) {
										username = user.user_name;
										return false;
									}
									});
								}
              let tion = "";
              if (msg.type == 0) {
                tion = "正常库";
              } else if (msg.type == 1) {
                tion = "公海";
              } else if (msg.type == 2) {
                tion = "已删除";
              }
              lef.hasVertify4 = true;
              lef.correct4 = false;
              lef.errorTitle4 =
                "该联系人邮箱对应的客户已存在于 [" +
                username +
                "] 的客户中，位置 [" +
                tion +
                "]";
              lef.$message({
                showClose: true,
                message:
                  "该联系人邮箱对应的客户已存在于 [" +
                  username +
                  "] 的客户中，位置 [" +
                  tion +
                  "]",
                type: "warning",
                duration: 2000
              });
              lef.confirmLoad=false
            }
          },
          error: function(e) {
            lef.hasVertify4 = true;
            lef.correct4 = false;
            lef.errorTitle4 =
              "检查联系人邮箱重复性网络异常，请刷新界面后重试！";
              lef.confirmLoad=false
          }
        });
      } else {
        this.hasVertify4 = true;
        this.correct4 = false;
        this.errorTitle4 = "联系人邮箱格式不正确!";
         this.$message({
          message:this.errorTitle4,
          type:'warning'
        })
        return;
      }
    },
    //国家赋值
    country_init() {
      this.country = $("#cusM_Country").val();
    },
    //验证国家
    country_verify() {
      if (
        $("#os_name")
          .val()
          .trim().length == 0
      ) {
        this.hasVertify3 = true;
        this.correct3 = false;
        this.errorTitle3 = "国家不能为空!";
        return;
      }
      this.hasVertify3 = true;
      this.correct3 = true;
    },
    //验证电话
    phone_verify() {
      if ($.trim(this.phone).length == 0) {
        this.hasVertify5 = true;
        this.correct5 = false;
        this.errorTitle5 = "联系人电话不能为空!";
        return;
      }
      this.hasVertify5 = true;
      this.correct5 = true;
    },
    //验证省份
    province_verify() {
      if (this.province.trim().length == 0) {
        this.hasVertify6 = true;
        this.correct6 = false;
        this.errorTitle6 = "省份/州/区不能为空!";
        return;
      }
      this.hasVertify6 = true;
      this.correct6 = true;
    },
    //验证城市
    city_verify() {
      if (this.city.trim().length == 0) {
        this.hasVertify7 = true;
        this.correct7 = false;
        this.errorTitle7 = "城市不能为空!";
        return;
      }
      this.hasVertify7 = true;
      this.correct7 = true;
    },
    //验证企业简称
    sname_verify() {
      if (this.sname.trim().length == 0) {
        this.hasVertify8 = true;
        this.correct8 = false;
        this.errorTitle8 = "企业简称不能为空!";
        return;
      }
      this.hasVertify8 = true;
      this.correct8 = true;
    },
    //验证手机
    mobile_verify() {
      if (this.mobile.trim().length == 0) {
        this.hasVertify9 = true;
        this.correct9 = false;
        this.errorTitle9 = "手机不能为空!";
        return;
      }
      this.hasVertify9 = true;
      this.correct9 = true;
    },
    //验证职位
    post_verify() {
      if (this.post.trim().length == 0) {
        this.hasVertify10 = true;
        this.correct10 = false;
        this.errorTitle10 = "职位不能为空!";
        return;
      }
      this.hasVertify10 = true;
      this.correct10 = true;
    },
    //验证传真
    fax_verify() {
      if (this.fax.trim().length == 0) {
        this.hasVertify11 = true;
        this.correct11 = false;
        this.errorTitle11 = "传真不能为空!";
        return;
      }
      this.hasVertify11 = true;
      this.correct11 = true;
    },
    //验证网址
    web_verify() {
      if (this.web.trim().length == 0) {
        this.hasVertify12 = true;
        this.correct12 = false;
        this.errorTitle12 = "网址不能为空!";
        return;
      }
      this.hasVertify12 = true;
      this.correct12 = true;
    },
    //验证地址
    address_verify() {
      if (this.address.trim().length == 0) {
        this.hasVertify13 = true;
        this.correct13 = false;
        this.errorTitle13 = "地址不能为空!";
        return;
      }
      this.hasVertify13 = true;
      this.correct13 = true;
    },
    addByLink(){
       this.confirmLoad=true
      this.loadingTitle='正在添加客户'
        //验证客户全称
      if(!this.companyTure){
        this.cusname_varify();
      }
      //验证邮箱
      if(!this.emailTrue){
         this.email_verify()
      }
      if(this.emailTrue && this.companyTure && this.confirmLoad ){
          this.addAjax()
      }
    },
    //联系人转为客户
    addAjax: function() {
      this.loadingTitle='正在添加客户'
      //验证客户全称
      if (!this.companyTure) {
        this.cusname_varify();
      }
      if (!this.companyTure) {
        if (this.cus_name == "") {
          this.$message({
            type: "warning",
            message: "请输入企业全称！",
            duration: 2000
          });
        } else {
          this.$message({
            type: "warning",
            message: "正在验证企业全称，请稍后操作！",
            duration: 2000
          });
        }
        return;
      }
      if (!this.correct1) {
        this.$message({
          type: "warning",
          showClose: true,
          message: this.errorTitle1,
          duration: 2000
        });
        return;
      }
      //验证姓名
      this.linkname_verify();
      if (!this.correct2) {
        this.$message({
          type: "warning",
          showClose: true,
          message: this.errorTitle2,
          duration: 2000
        });
        return;
	  }
      //验证邮箱
      if (!this.emailTrue) {
        this.email_verify();
      }
      if (!this.emailTrue) {
        if (this.email == "") {
          this.$message({
            type: "warning",
            message: "请输入联系人邮箱！",
            duration: 2000
          });
        } else {
          this.$message({
            type: "warning",
            message: "正在验证联系人邮箱，请稍后操作！",
            duration: 2000
          });
        }
        return;
      }
      if (!this.correct4) {
        this.$message({
          type: "warning",
          showClose: true,
          message: this.errorTitle4,
          duration: 2000
        });
        return;
      }
      //判断企业设置的必填项
      if (this.isShow1) {
        //国家必填
        this.country_verify();
        if (!this.correct3) {
          this.$message({
            type: "warning",
            showClose: true,
            message: this.errorTitle3,
            duration: 2000
          });
          return;
        }
      }
      if (this.isShow2) {
        //联系人电话必填
        this.country_verify();
        if (!this.correct4) {
          this.$message({
            type: "warning",
            showClose: true,
            message: this.errorTitle4,
            duration: 2000
          });
          return;
        }
      }
      if (this.isShow3) {
        //省份/州/区必填
        this.province_verify();
        if (!this.correct6) {
          this.$message({
            type: "warning",
            showClose: true,
            message: this.errorTitle6,
            duration: 2000
          });
          return;
        }
      }
      if (this.isShow4) {
        //城市必填
        this.city_verify();
        if (!this.correct7) {
          this.$message({
            type: "warning",
            showClose: true,
            message: this.errorTitle7,
            duration: 2000
          });
          return;
        }
      }
      if (this.isShow5) {
        //企业简称必填
        this.sname_verify();
        if (!this.correct8) {
          this.$message({
            type: "warning",
            showClose: true,
            message: this.errorTitle8,
            duration: 2000
          });
          return;
        }
      }
      if (this.isShow6) {
        //手机必填
        this.mobile_verify();
        if (!this.correct9) {
          this.$message({
            type: "warning",
            showClose: true,
            message: this.errorTitle9,
            duration: 2000
          });
          return;
        }
      }
      if (this.isShow7) {
        //职位必填
        this.post_verify();
        if (!this.correct10) {
          this.$message({
            type: "warning",
            showClose: true,
            message: this.errorTitle10,
            duration: 2000
          });
          return;
        }
      }
      if (this.isShow8) {
        //传真必填
        this.fax_verify();
        if (!this.correct11) {
          this.$message({
            type: "warning",
            showClose: true,
            message: this.errorTitle11,
            duration: 2000
          });
          return;
        }
      }
      if (this.isShow9) {
        //网址必填
        this.web_verify();
        if (!this.correct12) {
          this.$message({
            type: "warning",
            showClose: true,
            message: this.errorTitle12,
            duration: 2000
          });
          return;
        }
      }
      if (this.isShow10) {
        //地址必填
        this.address_verify();
        if (!this.correct13) {
          this.$message({
            type: "warning",
            showClose: true,
            message: this.errorTitle13,
            duration: 2000
          });
          return;
        }
      }

      var manyphone = "";

      if (this.showT) {
        // 多电话
        var phone1 = "";

        if (
          $("#cus_phone1").val() != "" &&
          $("#cus_phone1").val().length != 0
        ) {
          phone1 = $("#cus_phone1").val();
        }

        manyphone = phone1;

        var phone2 = "";

        if (
          $("#cus_phone2").val() != "" &&
          $("#cus_phone2").val().length != 0
        ) {
          phone2 = $("#cus_phone2").val();
        }

        if (manyphone != "") {
          if (phone2 != "") {
            manyphone += "@G@" + phone2;
          }
        } else {
          manyphone = phone2;
        }
      }

      // 有联系电话
      if (
        $("#cusM_mobile").val() != "" &&
        $("#cusM_mobile").val().length != 0
      ) {
        if (manyphone != "") {
          manyphone = $("#cusM_mobile").val() + "@G@" + manyphone;
        } else {
          manyphone = $("#cusM_mobile").val();
        }
      }

      // 验证数量是否足够
      if (this.daySurplusCusCounts == 0) {
        this.$message({
          type: "warning",
          showClose: true,
          message: "您今天创建客户数量已超标，请联系客服人员进行确认！",
          duration: 2000
        });
        return;
      }

      if (this.dateValue != "") {
        var myDate = new Date(this.dateValue);
        var year = myDate.getFullYear().toString();
        var month = (myDate.getMonth() + 1).toString();
        var day = myDate.getDate().toString();
        if (month.length == 1) {
          month = "0" + month;
        }
        if (day.length == 1) {
          day = "0" + day;
        }
        this.dateValue = year + "-" + month + "-" + day;
      }
      //所在国家
      if (
        $("#os_name")
          .val()
          .trim() != ""
      ) {
        if (
          $("#os_name")
            .val()
            .trim().length > 200
        ) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "所在国家不能超过200个字符！",
            duration: 2000
          });
          return;
        }
      }
      //联系人电话
      if ($.trim(this.phone) != "") {
        if ($.trim(this.phone).length > 100) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "联系人电话不能超过100个字符！",
            duration: 2000
          });
          return;
        }
      }

      //电话1
      if ($.trim($("#cus_phone1").val()) != "") {
        if ($.trim($("#cus_phone1").val()).length > 100) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "电话1不能超过100个字符！",
            duration: 2000
          });
          return;
        }
      }

      //电话2
      if ($.trim($("#cus_phone2").val()) != "") {
        if ($.trim($("#cus_phone2").val()).length > 100) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "电话2不能超过100个字符！",
            duration: 2000
          });
          return;
        }
      }
      var cusAddField = $(".addNumLink");
      var arr = [];
      var lef = this;
      if (cusAddField.length > 0) {
        var obj = {};
        var err = 0;
        for (var j = 0, len = cusAddField.length; j < len; j++) {
          obj = {};
          obj.name = cusAddField
            .eq(j)
            .find(".linkfinedname")
            .val();
          obj.value = cusAddField
            .eq(j)
            .find(".linkfinedvalue")
            .val();
          arr.push(obj);
          if (
            (obj.name.trim().length > 0 && obj.value.trim().length == 0) ||
            (obj.name.trim().length == 0 && obj.value.trim().length > 0)
          ) {
            lef.$message({
              showClose: true,
              message: "自定义字段名称和对应值必须同时填写!",
              type: "warning",
              duration: 2000
            });
            err = 1;
            break;
          }
        }
        if (err == 1) {
          return;
        }
      }
      //自定义字段拼接
      var nameStr = "";
      if (arr.length != 0) {
        $.each(arr, function(i, row) {
          nameStr += row.name + "###" + row.value + "@@G@@";
        });
        nameStr = nameStr.substring(0, nameStr.length - 5); //截取最后5个字符
      }

      //省份/州/区
      if ($.trim(this.province) != "") {
        if ($.trim(this.province).length > 100) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "省份/州/区不能超过100个字符！",
            duration: 2000
          });
          return;
        }
      }
      //城市
      if ($.trim(this.city) != "") {
        if ($.trim(this.city).length > 100) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "城市不能超过100个字符！",
            duration: 2000
          });
          return;
        }
      }
      //企业简称
      if ($.trim(this.sname) != "") {
        if ($.trim(this.sname).length > 50) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "企业简称不能超过50个字符！",
            duration: 2000
          });
          return;
        }
      }
      //手机
      if ($.trim(this.mobile) != "") {
        if ($.trim(this.mobile).length > 50) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "手机不能超过50个字符！",
            duration: 2000
          });
          return;
        }
      }
      //职位
      if ($.trim(this.post) != "") {
        if ($.trim(this.post).length > 50) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "职位不能超过50个字符！",
            duration: 2000
          });
          return;
        }
      }
      //传真
      if ($.trim(this.fax) != "") {
        if ($.trim(this.fax).length > 50) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "传真不能超过50个字符！",
            duration: 2000
          });
          return;
        }
      }
      //网址
      if ($.trim(this.web) != "") {
        if ($.trim(this.web).length > 100) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "网址不能超过100个字符！",
            duration: 2000
          });
          return;
        }
      }
      //地址
      if ($.trim(this.address) != "") {
        if ($.trim(this.address).length > 300) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "地址不能超过300个字符！",
            duration: 2000
          });
          return;
        }
      }
      //facebook
      if ($.trim(this.facebook) != "") {
        if ($.trim(this.facebook).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "facebook不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      //twitter
      if ($.trim(this.twitter) != "") {
        if ($.trim(this.twitter).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "twitter不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      //linkedin
      if ($.trim(this.linkedin) != "") {
        if ($.trim(this.linkedin).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "linkedin不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }

      //pinterest
      if ($.trim(this.pinterest) != "") {
        if ($.trim(this.pinterest).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "pinterest不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      //google
      if ($.trim(this.google) != "") {
        if ($.trim(this.google).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "google不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      //tumblr
      if ($.trim(this.tumblr) != "") {
        if ($.trim(this.tumblr).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "tumblr不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      //instagram
      if ($.trim(this.instagram) != "") {
        if ($.trim(this.instagram).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "instagram不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      //vk
      if ($.trim(this.vk) != "") {
        if ($.trim(this.vk).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "vk不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      //make_in_china
      if ($.trim(this.make_in_china) != "") {
        if ($.trim(this.make_in_china).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "make_in_china不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      //alibaba
      if ($.trim(this.alibaba) != "") {
        if ($.trim(this.alibaba).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "alibaba不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      //skype
      if ($.trim(this.skype) != "") {
        if ($.trim(this.skype).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "skype不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      //youtube
      if ($.trim(this.youtube) != "") {
        if ($.trim(this.youtube).length > 500) {
          this.$message({
            type: "warning",
            showClose: true,
            message: "youtube不能超过500个字符！",
            duration: 2000
          });
          return;
        }
      }
      var lef = this;
      var option;
      if (lef.linkId != 0 && lef.linkId != undefined && lef.linkId != null) {
        //联系人转为客户
        //提交
        option = {
          url: backUrl + "cus_addLinkToCustomer.action",
          dataType: "json",
          data: {
            token: token,
            "crmcustomer2.operate_time": lef.dateValue,
            manyphone: manyphone,
            "crmcustomer2.prductid":lef.commonJs.returnString(lef.productid) ,
            "crmcustomer2.customer_field": nameStr
          },
          //						async: false,
          beforeSend: function() {
            lef.$store.state.popupLoading = true;
          },
          complete: function() {
            lef.$store.state.popupLoading = false;
          },
          success: function(msg) {
            if (msg > 0) {
              lef.$message({
                type: "success",
                showClose: true,
                message: "添加新客户成功！",
                duration: 2000
              });
              lef.$emit("okReturn", true, msg);
            } else {
              lef.$message({
                type: "warning",
                showClose: true,
                message: "添加新客户失败！",
                duration: 2000
              });
            }
          }
        };
      } else {
        //陌生人转为客户
        option = {
          url: backUrl + "cus_addCustomerInfo.action",
          dataType: "json",
          //						async: false,
          data: {
            token: token,
            many_linkman: "",
            manyphone: manyphone,
            "crmcustomer2.prductid":lef.commonJs.returnString(lef.productid),
            "crmcustomer2.customer_field": nameStr
          },
          beforeSend: function() {
          lef.$store.state.popupLoading = true;
          lef.crmPopupLoading=true
        },
        complete: function() {
          lef.$store.state.popupLoading = false;
          lef.crmPopupLoading=false
        },
          success: function(msg) {
            if (msg > 0) {
              lef.$message({
                type: "success",
                showClose: true,
                message: "添加新用户成功，3分钟内邮件例表的图标将会改变！",
                duration: 2000
              });
              lef.$emit("okReturn", true, msg);
            } else {
              lef.$message({
                type: "warning",
                showClose: true,
                message: "添加新客户失败！",
                duration: 2000
              });
            }
          }
        };
      }
      myAjaxSubmit("link_customerinfoForm", option);
    },
    //添加自定义字段
    addfield(event) {
      var self = this;
      var target = $(event.currentTarget);
      var target_tr = target
        .parent()
        .parent()
        .siblings();
      if (target_tr.hasClass("addNumLink")) {
        this.counts++;
      } else {
        this.counts = 0;
      }
      var _html = "";
      _html =
        '<tr class="addNumLink" addNumLink="' +
        this.counts +
        '">' +
        '<td class="cu_tbg"><span>名称</span></td>' +
        '<td><input type="text" class="custx linkfinedname" value="" style="float: left;"/></td>' +
        '<td class="cu_tbg"><span>对应值</span></td>' +
        '<td><input type="text" class="custx linkfinedvalue" value="" style="float: left;"/>' +
        '<span class="unfold" id="addtrid' +
        this.counts +
        '" title="删除自定义字段" style="margin-left: 5px;margin-top: 7px;"></span></td>' +
        "</tr>";
      target
        .parents()
        .find(".link_myfield")
        .after(_html);

      $("#addtrid" + this.counts).click(function(e) {
        var target2 = $(e.currentTarget)
          .parent()
          .parent();
        var addNum = target2.attr("addNumLink");
        target2.remove();
      });
    }
  },
  props: ["linkId", "strangeName", "strangeMail"],
  computed: {
    ...mapState({
      customerclassifyall: state => state.customerclassifyall,
      customerfromall: state => state.customerfromall,
      customerrelationall: state => state.customerrelationall,
      customerstatusall: state => state.customerstatusall,
      industryType: state => state.hangyeArr,
      majorproductall: state => state.majorproductall,
      necessaryitemall: state => state.necessaryitemall,
      countryArr: state => state.countryArr,
      userparamlist: state => state.userparamlist,
      companyparamlist: state => state.companyparamlist,
      userlistall: state => state.userlistall,
      daySurplusCusCounts: state => state.daySurplusCusCounts
    })
  },
  mounted() {
    // 	//加载国家
    // 	availableTags = [];
    // 	$.each(this.countryArr,function(index,row){
    // 		availableTags.push(row.name);
    // 	})
    // //	availableTags = this.countryArr;
    // 	autoComp();
    if (this.linkId != 0 && this.linkId != undefined && this.linkId != null) {
      this.getLinkman(); //获取联系人
    } else {
      this.cus_name = this.strangeName;
      this.link_name = this.strangeName;
      this.email = this.strangeMail;
    }

    this.handleClassify(); //处理客户分类
    this.handleSourcefrom(); //处理客户来源
    this.handleRelation(); //处理客户关系
    this.handleStatus(); //处理客户状态
    this.handleYanghe(); //处理所属行业
    this.handleProduct(); //处理关联产品
    this.handleNecessary(); //处理必填项
  },
   watch:{
    companyLoad:function(val){
      if(!val){
        this.companyTure=true
         if(this.emailTrue && this.companyTure && this.confirmLoad){
          this.addAjax()
        }
      }else{
        this.companyTure=false
      }
    },
    emailLoad:function(val){
      if(!val){
        this.emailTrue=true
        if(this.emailTrue && this.companyTure && this.confirmLoad){
          this.addAjax()
        }
      }else{
        this.emailTrue=false
      }
    },
  }
};
</script>
<style lang="less">
.createTab-content {
  .el-input__icon {
    right: 6px;
  }
}

.country-list {
  position: absolute;
  top: 35px;
  border: 1px solid #ddd;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  width: 180px;
  z-index: 99999;
  li {
    padding: 5px;
    cursor: default;
    white-space: normal;
    &:hover {
      .ct-active;
    }
  }
  .ct-active {
    background-color: #20a0ff;
    color: #fff;
  }
}

.fold {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url("../../../../static/img/crm_images/icon/right.png");
}

.unfold {
  .fold;
  background: url("../../../../static/img/crm_images/icon/wrong.png");
}
</style>